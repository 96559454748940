@import '~antd/dist/antd.css';
@import '~react-quill/dist/quill.snow.css';
.loginScreen {
   background-image: linear-gradient(135deg,rgba(0,0,0,.8),rgba(0,8,57,.6)),url("/src/img/login_bg.jpeg") !important;
   height: 100vh;
   width: 100%;
   color: #f8f8ff;
   display: flex;
   justify-content: center;
   align-items: center;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
   background-color: #ffa41b;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
   background-color: #ffa41b;
}


.ant-btn-primary {
   color: #fff;
   background: #ffa41b;
   border-color: #ffa41b;
   text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
   -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
   box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary:active {
   color: #fff;
   background: #ffa41b !important;
   border-color: #ffa41b !important;
}

.ant-btn-primary:hover {
   color: #fff;
   background: #ffa41b ;
   border-color: #ffa41b ;
}

.ant-btn:active {
   color: #ffa41b;
   background: #fff;
   border-color: #ffa41b;
}

.ant-btn:hover {
   color: #ffa41b;
   background: transparent;
   border-color: #ffa41b;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
   color: #fff;
   background: #ffa41b;
   border-color: #ffa41b;
}


.ant-form-item-label > label {
   font-weight: 500;
}

.ant-upload-list-picture-card-container {
   display: inline-block;
   width: 180px;
   height: 180px;
   margin: 0 8px 8px 0;
   vertical-align: top;
  
}

.ant-upload.ant-upload-select-picture-card {
   width: 180px;
   height: 180px;
   margin-right: 8px;
   margin-bottom: 8px;
   text-align: center;
   vertical-align: top;
   background-color: #fafafa;
   border: 1px dashed #d9d9d9;
   border-radius: 2px;
   cursor: pointer;
   -webkit-transition: border-color 0.3s;
   transition: border-color 0.3s;
 
}

.ant-image-preview-operations{
   background: rgba(0,0,0,.1);
   backdrop-filter: saturate(180%) blur(10px);
 }
 
 /* .ant-image-preview-img {
   max-width: 80% !important;
 } */
 
 .ant-image-preview-img-wrapper {
   background: rgba(0,0,0,.1) ;
   backdrop-filter: saturate(180%) blur(10px);
 }

 .ql-editor{
    font-size: 16px;
 }